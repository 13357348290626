/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import initialStates from '../store/initialStates';
import isEmpty from 'lodash/isEmpty';
import { updateThemeWithFallback } from '../themes/CustomTheme';
import axios from 'axios';

function roots(state = {}, action) {
  switch (action.type) {
    case 'RELOAD':
      return {
        ...initialStates.roots,
        appContent: state.appContent,
        config: state.config,
        availLanguages: state.availLanguages,
        userUnitSystems: state.userUnitSystems,
        entityView: state.entityView,
        perms: state.perms,
        filterData: state.filterData,
        filterDataStatus: 'RELOAD',
        defaultViewShown: sessionStorage.getItem(state.appContent.shortname + '-defaultViewShown') || false,
        hasSearchCriteria: state.hasSearchCriteria,
      };
    case 'CLEAR_MATERIALS':
      sessionStorage.setItem(state.appContent.shortname + '-filterData', '{}');
      sessionStorage.setItem(state.appContent.shortname + '-savedSearchArray', '[]');

      return {
        ...initialStates.roots,
        canAccess: state.canAccess,
        canImport: state.canImport,
        appContent: state.appContent,
        entityView: state.entityView,
        preferences: state.preferences,
        language: state.language,
        config: state.config,
        texts: state.texts,
        units: state.units,
        tableColumns: state.tableColumns,
        unitSystem: state.unitSystem,
        availLanguages: state.availLanguages,
        userUnitSystems: state.userUnitSystems,
        searchCatalog: state.searchCatalog,
        scatterCatalog: state.scatterCatalog,
        selectedEntities: state.selectedEntities,
        perms: state.perms,
        compareDetails: state.compareDetails,
        compareCatalog: state.compareCatalog,
        defaultViewShown: state.defaultViewShown,
        polarCatalog: state.polarCatalog,
        resultSummary: state.resultSummary,
        impactAssessmentData: state.impactAssessmentData,
        lcaGeneric: state.lcaGeneric,
        bbPageForDialog: state.bbPageForDialog,
        bbPageForComponent: state.bbPageForComponent,
        hasSearchCriteria: state.hasSearchCriteria,
        bbFooter: state.bbFooter,
        bbTopBar: state.bbTopBar,
        bbWaffleMenu: state.bbWaffleMenu,
        favoriteList: state.favoriteList,
        appTitle: state.appTitle,
      };
    case 'FETCH_FAILED_MATERIALS':
      return {
        ...initialStates.roots,
        canAccess: state.canAccess,
        canImport: state.canImport,
        status: 'FETCH_FAILED',
      };
    case 'GET_APP_CONTENT_FAIL':
      return {
        ...state,
        status: 'APP_CONTENT_FAIL',
      };
    case 'GETTING_MATERIALS':
      return {
        ...state,
        status: 'FETCHING',
      };
    case 'CLEAR_CAE_DOWNLOAD_STATUS':
      return {
        ...state,
        caeDownloadStatus: null,
      };
    case 'DOWNLOADING_MATERIAL_CARD_FAILED':
      // show downloading is failed.
      return {
        ...state,
        caeDownloadStatus: action,
      };
    case 'DOWNLOADING_MATERIAL_CARD_SUCCESS':
      // show downloading is successful.
      return {
        ...state,
        caeDownloadStatus: action,
      };
    case 'DOWNLOADING_MATERIAL_CARD':
      // show downloading has started
      return {
        ...state,
        caeDownloadStatus: action,
      };
    case 'REPLACE_MATERIALS_CLASSIFICATIONS':
      return {
        ...state,
        classifications: action.classifications,
        tags: action.tags,
      };
    case 'REPLACE_MATERIALS':
      let newTrack = new Map(state.track);
      if (action.track) {
        for (let item of action.track) {
          newTrack.set(item.id, item);
        }
      }

      return {
        ...state,
        counts: action.counts,
        items:
          state.offset === 0 ? (action.items && action.items.length ? action.items : [action.items]) : state.items.concat(action.items),
        status: action.success ? 'SUCCESS' : 'FAILURE',
        previousFilterData: state.filterData,
        filterData: action.filterData,
        hasMaterials: true,
        hasMore: state.offset + state.pageSize < action.totalCount,
        filterDataStatus: 'CURRENT',
        storedTags: action.storedTags,
        totalCount: action.totalCount,
        track: newTrack,
        offset: action.offset ? action.offset : state.offset,
      };
    case 'REPLACE_FILTER_DATA':
      if (isEmpty(action.filterData.idfilter)) {
        sessionStorage.setItem(state.appContent.shortname + '-filterData', JSON.stringify(action.filterData));
      }
      return {
        ...state,
        previousFilterData: state.filterData,
        filterData: action.filterData,
        filterDataStatus: 'STALE',
        firstLoad: true,
        items: [],
        offset: 0,
      };
    case 'UPDATE_LOAD_INDEX':
      return {
        ...state,
        firstLoad: action.firstLoad,
      };
    case 'UPDATE_OFFSET':
      return {
        ...state,
        offset: action.offset,
      };
    case 'CLEAN_ENTITY_ITEMS':
      return {
        ...state,
        items: [],
      };
    case 'CAE_DOWNLOAD_PREVIEW':
      return {
        ...state,
        caeDownloadPreview: action.data,
      };
    case 'REPLACE_PROVIDERS':
      return {
        ...state,
        // counts: action.counts,
        providers: action.providers,
      };
    case 'REPLACE_UNIT_SYSTEMS':
      return {
        ...state,
        // counts: action.counts,
        unitSystems: action.unitSystems,
      };
    case 'REPLACE_SOLVERS':
      return {
        ...state,
        // counts: action.counts,
        solvers: action.solvers,
      };
    case 'REPLACE_TEXTS':
      return {
        ...state,
        // counts: action.counts,
        texts: action.texts,
      };
    case 'REPLACE_CAE_PLOTS':
      if (action.plots.CAEStressStrain && action.plots.CAEStressStrain.catalog) {
        action.plots.CAEStressStrain.catalog._key = action.plots.CAEStressStrain.catalog._key + 'cae';
      }
      return {
        ...state,
        // counts: action.counts,
        CAEPlots: action.plots,
      };
    case 'REPLACE_CAE_SELECTED_DIAGRAM':
      /*if (action.plots.CAEStressStrain && action.plots.CAEStressStrain.catalog) {
        action.plots.CAEStressStrain.catalog._key = action.plots.CAEStressStrain.catalog._key + 'cae';
      }*/
      return {
        ...state,
        // counts: action.counts,
        CAESelectedDiagram: action.plots,
      };
    case 'REPLACE_USER_PREFS':
      return {
        ...state,
        // counts: action.counts,
        preferences: action.preferences,
        language: state.languageOverride
          ? state.languageOverride
          : action.preferences.language
          ? action.preferences.language
          : state.availLanguages.default,
        unitSystem: action.preferences.unitSystem ? action.preferences.unitSystem : state.userUnitSystems.default,
      };
    case 'USER_PREFS_CHANGED':
      return {
        ...state,
        // counts: action.counts,
        preferences: action.preferences,
        language: action.preferences.language ? action.preferences.language : state.availLanguages.default,
        unitSystem: action.preferences.unitSystem ? action.preferences.unitSystem : state.userUnitSystems.default,
        status: initialStates.roots.status,
        searchCatalog: initialStates.roots.searchCatalog,
        tableColumns: initialStates.roots.tableColumns,
        hasMaterials: initialStates.roots.hasMaterials,
        offset: initialStates.roots.offset,
        filterDataStatus: 'RELOAD',
        //filterData: { new: true, featfilter: [] },
        items: initialStates.roots.items,
        classifications: initialStates.roots.classifications,
        languageOverride: action.preferences.language !== state.language ? null : state.languageOverride,
      };
    case 'REPLACE_UNITS':
      return {
        ...state,
        // counts: action.counts,
        units: action.units,
      };
    case 'SET_RANGES_LOADING':
      return {
        ...state,
        rangeStatus: 'LOADING',
      };
    case 'REPLACE_RANGES':
      return {
        ...state,
        // counts: action.counts,
        ranges: action.ranges,
        advSearchRanges: action.advSearchRanges,
        rangeStatus: 'SUCCESS',
      };
    case 'REPLACE_SI_UNIT_SYSTEM':
      return {
        ...state,
        // counts: action.counts,
        siUnitSystem: action.siUnitSystem,
      };
    case 'REPLACE_APPLICATION_MENU':
      return {
        ...state,
        // counts: action.counts,
        supportedApplicationMenus: action.supportedApplicationMenus,
      };
    case 'SET_ACTIVE_APP':
      return {
        ...state,
        activeApp: action.activeApp || initialStates.roots.activeApp,
      };
    case 'SET_APP_CONTENT':
      axios.defaults.headers.common['skipLicenseCache'] = false;

      let appContent = action.appContent;

      if (appContent.config && appContent.config.semanticcolors) {
        let fallbackColors = appContent.config.semanticcolors.fallbackColors;

        for (let [key, value] of Object.entries(appContent.config.semanticcolors)) {
          if (key !== 'fallbackColors' && key !== 'default') {
            let newColors = updateThemeWithFallback(value, key, fallbackColors);
            appContent.config.semanticcolors[key] = newColors;
          }
        }
      }

      if (Array.isArray(appContent)) {
        for (let element of appContent) {
          if (element.default) {
            appContent = element;
          }
        }
      }
      return {
        ...state,
        appContent: appContent,
        config: appContent.config ? appContent.config : {},
        availLanguages: appContent.languages ? appContent.languages : {},
        userUnitSystems: appContent.unitsystems ? appContent.unitsystems : {},
        filterData: JSON.parse(sessionStorage.getItem(appContent.shortname + '-filterData') || '{}'),
        entityView: localStorage.getItem(appContent.shortname + '-entityView') || 'tiles',
        theme: localStorage.getItem(appContent.shortname + '-theme') || 'light',
        favoriteList: [],
        defaultViewShown: sessionStorage.getItem(appContent.shortname + '-defaultViewShown') || false,
        languageOverride: action.languageOverride ? action.languageOverride : null,
      };
    case 'SET_UI_PERMS':
      let canAccess = initialStates.roots.canAccess;
      let canImport = initialStates.roots.canImport;

      if (action && action.perms && action.perms.user && action.perms.user.license && action.perms.user.license.length > 0) {
        canAccess = true;
      } else {
        canAccess = false;
      }

      if (
        action &&
        action.perms &&
        action.perms.user &&
        action.perms.user.permissions &&
        action.perms.user.permissions.length > 0 &&
        action.perms.user.permissions.includes('ui.authoring')
      ) {
        canImport = true;
      } else {
        canImport = false;
      }

      return {
        ...state,
        canImport: canImport,
        canAccess: canAccess,
        perms: action.perms?.user?.permissions,
        uicheckRun: true,
      };
    case 'SET_PLOT_JSON':
      return {
        ...state,
        plotJson: action.plotJson || initialStates.roots.plotJson,
      };
    case 'SET_PLOT_JSONMAP':
      return {
        ...state,
        plotJsonMap: action.plotJsonMap || initialStates.roots.plotJsonMap,
      };
    case 'SET_CONTENT_DEFINITIONS':
      return {
        ...state,
        contentDefinitions: action.contentDefinitions || initialStates.roots.contentDefinitions,
      };
    case 'SET_CATALOGS':
      return {
        ...state,
        catalogs: action.catalogs || initialStates.roots.catalogs,
      };
    case 'SET_SEARCH_CATALOG':
      return {
        ...state,
        searchCatalog: action.searchCatalog || initialStates.roots.searchCatalog,
      };
    case 'SET_UNITS':
      return {
        ...state,
        units: action.units || initialStates.roots.units,
      };
    case 'SET_COMPARE_CATALOG':
      return {
        ...state,
        compareCatalog: action.compareCatalog || initialStates.roots.compareCatalog,
      };
    case 'SET_TABLE_COLUMNS':
      return {
        ...state,
        tableColumns: action.tableColumns || initialStates.roots.tableColumns,
      };
    case 'SET_SCATTER_DATA':
      return {
        ...state,
        scatterData: action.scatterData || initialStates.roots.scatterData,
      };
    case 'SET_AVAIL_LANGUAGES':
      return {
        ...state,
        availLanguages: action.availLanguages || initialStates.roots.availLanguages,
      };
    case 'SET_DERIVED_CONTENT_DEFINITIONS':
      return {
        ...state,
        derivedContentDefinitions: action.derivedContentDefinitions || initialStates.roots.derivedContentDefinitions,
      };
    case 'DATASHEET_LOADING':
      return {
        ...state,
        datasheetStatus: 'LOADING',
      };
    case 'SET_MATERIAL_DETAILS':
      return {
        ...state,
        materialDetails: action.roots || initialStates.roots.materialDetails,
      };
    case 'SET_MATERIAL_DATASHEET':
      return {
        ...state,
        datasheetStatus: 'SUCCESS',
        materialDatasheet: action.roots || initialStates.roots.materialDatasheet,
        activeId: action.id || initialStates.roots.activeId,
        activeName: action.materialName || initialStates.roots.activeName,
        caeDownloadPreview: null,
      };
    case 'SET_COMPARE_DETAILS':
      return {
        ...state,
        compareDetails: Object.values(action.roots) || initialStates.roots.compareDetails,
      };
    case 'SET_LANDING_BB':
      return {
        ...state,
        landingBB: Object.values(action.blocks) || initialStates.roots.landingBB,
      };
    case 'SET_RESULT_SUMMARY':
      return {
        ...state,
        resultSummary: Object.values(action.blocks) || initialStates.roots.resultSummary,
      };
    case 'SET_BB_FOOTER':
      return {
        ...state,
        bbFooter: Object.values(action.blocks) || initialStates.roots.bbFooter,
      };
    case 'SET_BB_TOPBAR':
      return {
        ...state,
        bbTopBar: Object.values(action.blocks) || initialStates.roots.bbTopBar,
      };
    case 'SET_BB_WAFFLEMENU':
      return {
        ...state,
        bbWaffleMenu: Object.values(action.blocks) || initialStates.roots.bbWaffleMenu,
      };

    case 'SET_BB_ACCOUNTPANEL':
      return {
        ...state,
        bbAccountPanel: Object.values(action.blocks) || initialStates.roots.bbAccountPanel,
      };
    case 'SET_LCA_GENERIC':
      return {
        ...state,
        lcaGeneric: Object.values(action.blocks) || initialStates.roots.lcaGeneric,
      };
    case 'CLEAR_BB_PAGE_FOR_DIALOG':
      return {
        ...state,
        bbPageForDialog: [],
      };
    case 'SET_BB_PAGE_FOR_DIALOG':
      return {
        ...state,
        bbPageForDialog: Object.values(action.blocks) || initialStates.roots.bbPageForDialog,
      };
    case 'SET_BB_PAGE_FOR_COMPONENT':
      let newBBPageMap = new Map(state.bbPageForComponent);
      if (action.blocks) {
        newBBPageMap.set(action.page, action.blocks);
      }

      return {
        ...state,
        bbPageForComponent: newBBPageMap,
      };
    case 'SET_APP_TITLE':
      return {
        ...state,
        appTitle: Object.values(action.blocks) || initialStates.roots.appTitle,
      };
    case 'SET_BB_COMPARE_DETAILS':
      return {
        ...state,
        bbCompareDetails: Object.values(action.roots) || initialStates.roots.bbCompareDetails,
      };
    case 'MATERIAL_IMPORT_MODAL_OPEN':
      return {
        ...state,
        isImportModalOpened: action.isImportModalOpened,
      };
    case 'MATERIAL_EXPORT_MODAL_OPEN':
      return {
        ...state,
        isExportModalOpened: action.isExportModalOpened,
      };
    case 'SEARCH_CRITERIA_UPDATED':
      return {
        ...state,
        updateSearchCriteria: crypto.randomUUID(),
      };
    case 'MATERIAL_ENTITY_VIEW':
      return {
        ...state,
        entityView: action.entityView,
        items: initialStates.roots.items,
        offset: initialStates.roots.offset,
        pageSize: initialStates.roots.pageSize,
        firstLoad: initialStates.roots.firstLoad,
        hasMore: false,
      };
    case 'MATERIAL_THEME':
      return {
        ...state,
        theme: action.theme,
      };
    case 'SET_FAVORITE_LIST':
      return {
        ...state,
        favoriteList: action.favoriteList,
      };
    case 'SET_FAVORITE_VIEW':
      return {
        ...state,
        favoriteView: state.status === 'SUCCESS' ? action.favoriteView : state.favoriteView,
      };
    case 'SET_SIMILAR_ID':
      return {
        ...state,
        similarId: action.id,
      };
    case 'SET_NOTIFICATIONS':
      return {
        ...state,
        notifications: action.notifications,
      };
    case 'SET_SIMILAR_PROFILE':
      return {
        ...state,
        similarProfile: action.profile,
      };
    case 'PDF_DOWNLOAD_WAITING':
      let newSet = null;

      if (action.isWaiting) {
        newSet = new Set([...state.pdfDownloadWaiting]);
        newSet.add(action.id);
      } else {
        newSet = new Set([...state.pdfDownloadWaiting]);
        newSet.delete(action.id);
      }

      return {
        ...state,
        pdfDownloadWaiting: newSet,
      };
    case 'SET_POLAR_DATA_LOADING':
      return {
        ...state,
        polarLoadingStatus: 'LOADING',
      };
    case 'SET_POLAR_DATA':
      return {
        ...state,
        polarData: action.polarData,
        polarLoadingStatus: 'SUCCESS',
      };
    case 'SET_POLAR_CATALOG':
      return {
        ...state,
        polarCatalog: action.polarCatalog || initialStates.roots.polarCatalog,
      };

    case 'SET_SCATTER_CATALOG':
      return {
        ...state,
        scatterCatalog: action.scatterCatalog || initialStates.roots.scatterCatalog,
      };
    case 'USER_APP_ACCESS':
      let updatedAppPerms = {
        ...state.appsPerms,
      };
      Object.keys(action.perm).forEach((appPerm) => {
        updatedAppPerms[appPerm] = action.perm[appPerm];
      });
      return {
        ...state,
        appsPerms: updatedAppPerms,
      };
    case 'SET_IMPACT_ASSESSMENT_DATA':
      return {
        ...state,
        impactAssessmentData: action.impactAssessmentData || initialStates.roots.impactAssessmentData,
      };
    case 'EXPORT_COMPARE':
      return {
        ...state,
        exportCompare: action.exportCompare || initialStates.roots.exportCompare,
      };
    case 'SET_HAS_SEARCH_CRITERIA':
      return {
        ...state,
        hasSearchCriteria: true,
      };
    case 'SET_PLUGIN_SOURCE':
      return {
        ...state,
        pluginSource: action.source,
      };
    default:
      return state;
  }
}

export default roots;
