import { DefaultButton, Dropdown, Spinner, SpinnerSize, SearchBox } from '@fluentui/react';
import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { Margin5 } from '../../pages/styles';
import { FilterButton } from '../FilterButton';
import SaveCriteriaDialog from '../gooey/SearchForm/Dialogs/SaveCriteriaDialog';
import LoadCriteriaDialog from '../gooey/SearchForm/Dialogs/LoadCriteriaDialog';
import SaveCriteriaAsDialog from '../gooey/SearchForm/Dialogs/SaveCriteriaAsDialog';
import { getApi } from '../../api';
import get from 'lodash/get';
import * as TextMapping from '../../utils/textMapping';
import '../buildingBlockStyles.css';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import * as actions from '../../actions';

import AdvancedSearchTextField from './AdvancedSearchTextField';
import AdvancedSearchCheckbox from './AdvancedSearchCheckbox';
import AdvancedSearchSpecialValue from './AdvancedSearchSpecialValue';
import AdvancedSearchGroup from './AdvancedSearchGroup';
import AdvancedSearchRow from './AdvancedSearchRow';
import { convertUnit } from '../../utils/units';
import { isEmpty } from 'lodash';
import { ConfirmDialog } from '../dialogs/ConfirmDialog';
import BBPageComponent from '../BBPageComponent';
import { styled } from '@fluentui/utilities';

export const FORMAT = '2022-1';
export const FAVORITE_FORMAT = 'FAV-2022-1';

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(actions, dispatch);
};

function isNumeric(value) {
  let pattern = /^[+\-0-9eE.]*$/;
  return pattern.test(value);
}

const mathjs = require('mathjs');

const mapStateToProps = (state) => {
  return {
    hasMaterials: state.roots.hasMaterials,
    rangeStatus: state.roots.rangeStatus,
    hasSearchCriteria: state.roots.hasSearchCriteria,
    rootFilterData: state.roots.filterData,
    favoriteList: state.roots.favoriteList,
    config: state.roots.config,
    perms: state.roots.perms,
    bbPageForComponent: state.roots.bbPageForComponent,
    language: state.roots.language,
    similarId: state.roots.similarId,
    idfilter: state.roots.filterData?.idfilter,
  };
};

function AdvancedSearchBase({
  searchCatalog,
  ranges,
  qs,
  appContent,
  texts,
  history,
  updateBreadcrumbs,
  hasMaterials,
  rangeStatus,
  replaceFilterData,
  filterDataStatus,
  clearSearch,
  scatterUpdatedRanges,
  setHasSearchCriteria,
  hasSearchCriteria,
  rootFilterData,
  favoriteList,
  setFavoriteList,
  setFavoriteView,
  config,
  perms,
  bbPageForComponent,
  getBBPageForComponent,
  language,
  similarId,
  idfilter,
  theme,
}) {
  const [formFields, setFormFields] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [filter, setFilter] = useState('');
  const [groupOptions, setGroupOptions] = useState([]);
  const [searchMap, setSearchMap] = useState(new Map());
  const [unitMap, setUnitMap] = useState(new Map());
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [collapsedVkeys, setCollapsedVkeys] = useState([]);
  const [executedSearchMap, setExecutedSearchMap] = useState(null);
  const [isSaveDialogHidden, setIsSaveDialogHidden] = useState(true);
  const [isSaveAsDialogHidden, setIsSaveAsDialogHidden] = useState(true);
  const [isLoadDialogHidden, setIsLoadDialogHidden] = useState(true);
  const [currentName, setCurrentName] = useState('');
  const [currentId, setCurrentId] = useState(null);
  const [criterias, setCriterias] = useState([]);
  const [hasUnitMap, setHasUnitMap] = useState(false);
  const [fetchedSearchCriteria, setFetchedSearchCriteria] = useState(false);
  const [hadMaterials, setHadMaterials] = useState(false);
  const numberOfFieldsDisplayed = useRef(0);
  const savedSearchArray = useRef();
  const [isConfirmDialogVisible, setConfirmDialogVisible] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [restrictedPage, setRestrictedPage] = useState('');
  const [permission, setPermission] = useState(null);
  const [pendingChanges, setPendingChanges] = useState(false);
  const [previousCollapsedVkeys, setPreviousCollapsedVkeys] = useState([]);

  let api = getApi();

  function resetFilters() {
    clearSearch();

    const currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.delete('tags');
    currentUrlParams.delete('pattern');
    currentUrlParams.delete('favorites');

    history.push({
      pathname: window.location.pathname,
      search: currentUrlParams.toString(),
    });
  }

  const deserializeSearchArray = useCallback(
    (searchArray) => {
      let newSearchMap = new Map();

      for (let value of searchArray) {
        let currentObject = { type: value.type, name: value.name, unit: unitMap.get(value.key), baseunit: value.baseunit };
        if (value.type === 'propfilter') {
          if (value.min) {
            currentObject.min = convertUnit(value.min, value.baseunit, unitMap.get(value.key));
          }

          if (value.max) {
            currentObject.max = convertUnit(value.max, value.baseunit, unitMap.get(value.key));
          }

          if (value.text) {
            currentObject.text = value.text;
          }
        } else if (value.type === 'Mpt' || value.type === 'Feature') {
          currentObject.value = value.value;
        }

        if (currentObject.min || currentObject.max || currentObject.value || currentObject.text) {
          newSearchMap.set(value.key, currentObject);
        }
      }

      return newSearchMap;
    },
    [unitMap]
  );

  const handleLoadCriteria = useCallback(
    async (criteria) => {
      dismissLoadDialog();

      setCurrentName(criteria.name);
      setCurrentId(criteria.id);
      let newSearchMap = deserializeSearchArray(criteria.criterias);
      qs.current = criteria.qs;

      setSearchMap(new Map(newSearchMap));
      setExecutedSearchMap(new Map(newSearchMap));

      setPendingChanges(false);
    },
    [qs, deserializeSearchArray]
  );

  const getSearchCriteria = useCallback(
    async (replaceFavorites = false) => {
      if (appContent) {
        const { data } = await api.materials.getSearchCriteria(appContent, FORMAT);
        const criteriasData = get(data, 'criterias') || [];

        if (qs.current.favorites) {
          const { data } = await api.materials.getSearchCriteria(appContent, FORMAT, true);

          const favoritesData = get(data, 'criterias');

          for (let criteria of favoritesData) {
            if (qs.current.favorites === criteria.name) {
              let newFilterData = JSON.parse(JSON.stringify(rootFilterData)) || {};

              newFilterData.idfilter = criteria.criterias[0].value;

              newFilterData.new = true;

              if (!newFilterData.featfilter) {
                newFilterData.featfilter = [];
              }

              if (replaceFavorites) {
                setFavoriteList(criteria.criterias[0].value);
                setFavoriteView(true);
                replaceFilterData(newFilterData);
              }

              const currentUrlParams = new URLSearchParams(window.location.search);
              currentUrlParams.delete('tags');
              currentUrlParams.delete('pattern');
              currentUrlParams.delete('favorites');

              history.push({
                pathname: window.location.pathname,
                search: currentUrlParams.toString(),
              });

              break;
            }
          }
        }

        if (!qs.current.favorites && !hasSearchCriteria && !history.location.pathname.includes('datasheet')) {
          for (let criteria of criteriasData) {
            if (qs.current.searchcriteria) {
              if (qs.current.searchcriteria === criteria.shortname) {
                handleLoadCriteria(criteria);
                break;
              }
            } else {
              if (
                (isEmpty(rootFilterData) ||
                  (!isEmpty(rootFilterData) &&
                    isEmpty(rootFilterData.featfilter) &&
                    isEmpty(rootFilterData.diafilter) &&
                    isEmpty(rootFilterData.propfilter))) &&
                isEmpty(qs.current.tags) &&
                isEmpty(qs.current.pattern)
              ) {
                if (criteria.apply) {
                  handleLoadCriteria(criteria);
                  break;
                }
              }
            }
          }
        }

        let newCriterias = criteriasData.filter((criteria) => !isEmpty(criteria.id));
        setCriterias(newCriterias);

        setHasSearchCriteria();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [api.materials, appContent, rootFilterData, qs, hasSearchCriteria, setHasSearchCriteria, handleLoadCriteria]
  );

  const criteriaNames = useMemo(() => {
    return criterias.map(({ name }) => name);
  }, [criterias]);

  useEffect(() => {
    if (appContent && !hasSearchCriteria && hasUnitMap && !fetchedSearchCriteria) {
      setFetchedSearchCriteria(true);
      if (qs.current.favorites && favoriteList.length > 0) {
        setConfirmDialogVisible(true);
      } else {
        getSearchCriteria(true);
      }
    }
  }, [appContent, getSearchCriteria, hasSearchCriteria, fetchedSearchCriteria, hasUnitMap, favoriteList, qs]);

  useEffect(() => {
    if (appContent) {
      savedSearchArray.current = JSON.parse(sessionStorage.getItem(appContent.shortname + '-savedSearchArray') || '[]');
    }
  }, [appContent]);

  useEffect(() => {
    if (config && config.advsearchdialog && config.advsearchdialog.restricted) {
      setPermission(config.advsearchdialog.restricted.permission);
      setRestrictedPage(config.advsearchdialog.restricted.page);
    }
  }, [config]);
  useEffect(() => {
    if (searchCatalog && scatterUpdatedRanges) {
      let newSearchMap = createSearchMap(searchCatalog[0].children, scatterUpdatedRanges);
      setSearchMap(newSearchMap);
      let filterData = { new: true };
      let propfilter = [];
      let diafilter = [];
      let featureMap = new Map();

      for (const [key, value] of newSearchMap.entries()) {
        let filter = {};
        let keyParts = key.split('-');
        let vkey = keyParts[1];

        if (value.type === 'propfilter') {
          if (value.min && value.min !== '') {
            filter.min = convertUnit(value.min, value.unit, value.baseunit);
          }
          if (value.max && value.max !== '') {
            filter.max = convertUnit(value.max, value.unit, value.baseunit);
          }
          if (value.text && value.text !== '') {
            filter.text = value.text;
          }

          if (Object.keys(filter).length > 0) {
            filter.vkey = vkey;
            propfilter.push(filter);
          }
        } else if (value.type === 'Mpt') {
          if (value.value === true) {
            diafilter.push(parseInt(vkey));
          }
        } else if (value.type === 'Feature') {
          if (value.value === true) {
            //featfilter.push(parseInt(vkey));

            let useMask = '';
            if (value.parentUseMask && value.parentUseMask.length) {
              useMask = value.parentUseMask.join('-');
            }
            let mapKey = value.parentVkey + '-' + useMask;
            if (featureMap.has(mapKey)) {
              featureMap.set(mapKey, [...featureMap.get(mapKey), parseInt(vkey)]);
            } else {
              featureMap.set(mapKey, [parseInt(vkey)]);
            }
          }
        }
      }

      filterData.propfilter = propfilter;
      filterData.featfilter = [];
      filterData.diafilter = diafilter;

      if (featureMap.size > 0) {
        for (let featureList of featureMap.values()) {
          filterData.featfilter.push(featureList);
        }
      }

      if (similarId) {
        filterData.similar = similarId;
      }
      if (isEmpty(savedSearchArray.current)) {
        replaceFilterData(filterData);
      } else {
        savedSearchArray.current = [];
      }
      if (newSearchMap.size > 0) {
        setExecutedSearchMap(new Map(newSearchMap));
      } else {
        setExecutedSearchMap(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scatterUpdatedRanges]);

  const parseMeasurement = useCallback((input) => {
    input = input.toString();
    const regex = /^([+-]?\d+(\.\d+)?([eE][+-]?\d+)?)([/*()a-zA-Z²³°µΩ₂\d\s]*)$/;
    const match = input.match(regex);

    if (match) {
      return { value: parseFloat(match[1]), unit: match[4]?.trim() || null };
    } else {
      return { value: input, unit: null };
    }
  }, []);

  const convertInput = useCallback(
    (input, targetUnit) => {
      let convertedInput = input;
      let parsedInput = parseMeasurement(input);

      if (parsedInput && parsedInput.unit) {
        convertedInput = convertUnit(parsedInput.value, parsedInput.unit, targetUnit);
      }

      return convertedInput;
    },
    [parseMeasurement]
  );

  const executeSearch = useCallback(() => {
    let filterData = { new: true };
    let propfilter = [];
    let diafilter = [];

    let featureMap = new Map();

    for (const [key, value] of searchMap.entries()) {
      let filter = {};
      let keyParts = key.split('-');
      let vkey = keyParts[1];

      if (value.type === 'propfilter') {
        if (value.min && value.min !== '') {
          let minValue = value.min;

          let parsedValue = parseMeasurement(value.min);
          if (value.max) {
            let parsedMaxValue = parseMeasurement(value.max);
            if (!parsedValue.unit && parsedMaxValue.unit) {
              minValue = value.min + ' ' + parsedMaxValue.unit;
            }
          }

          filter.min = convertInput(minValue, value.baseunit);
        }
        if (value.max && value.max !== '') {
          let maxValue = value.max;
          let parsedValue = parseMeasurement(value.max);
          if (value.min) {
            let parsedMinValue = parseMeasurement(value.min);
            if (!parsedValue.unit && parsedMinValue.unit) {
              maxValue = value.max + ' ' + parsedMinValue.unit;
            }
          }
          filter.max = convertInput(maxValue, value.baseunit);
        }
        if (value.text && value.text !== '') {
          filter.text = value.text;
        }

        if (Object.keys(filter).length > 0) {
          filter.vkey = vkey;
          propfilter.push(filter);
        }
      } else if (value.type === 'Mpt') {
        if (value.value === true) {
          diafilter.push(parseInt(vkey));
        }
      } else if (value.type === 'Feature') {
        if (value.value === true) {
          let useMask = '';
          if (value.parentUseMask && value.parentUseMask.length) {
            useMask = value.parentUseMask.join('-');
          }
          let mapKey = value.parentVkey + '-' + useMask;
          if (featureMap.has(mapKey)) {
            featureMap.set(mapKey, [...featureMap.get(mapKey), parseInt(vkey)]);
          } else {
            featureMap.set(mapKey, [parseInt(vkey)]);
          }
        }
      }
    }

    filterData.propfilter = propfilter;
    filterData.featfilter = [];
    filterData.diafilter = diafilter;
    if (idfilter) {
      filterData.idfilter = idfilter;
    }

    if (featureMap.size > 0) {
      for (let featureList of featureMap.values()) {
        filterData.featfilter.push(featureList);
      }
    }

    if (similarId) {
      filterData.similar = similarId;
    }

    if (isEmpty(savedSearchArray.current)) {
      replaceFilterData(filterData);
    } else {
      savedSearchArray.current = [];
    }
  }, [searchMap, replaceFilterData, similarId, idfilter, convertInput, parseMeasurement]);

  useEffect(() => {
    if (filterDataStatus === 'STALE') {
      let searchStr = convertObjecttoQs(qs.current);
      let url = history.location.pathname + searchStr;

      history.push(url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDataStatus]);

  useEffect(() => {
    if (executedSearchMap) {
      executeSearch();
      sessionStorage.setItem(appContent.shortname + '-savedSearchArray', JSON.stringify(serializeSearchMap()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executedSearchMap]);

  const dropdownStyles = useMemo(() => {
    return { dropdown: { width: 300 }, dropdownOptionText: { fontSize: '10px' } };
  }, []);

  let fields = useRef([]);
  let groups = useRef([]);

  const handleRemove = useCallback(
    (key) => {
      setSearchMap(new Map(searchMap.set(key, {})));
      setExecutedSearchMap(new Map(searchMap.set(key, {})));
    },
    [searchMap]
  );

  useEffect(() => {
    let elements = [];
    if (executedSearchMap) {
      for (const [key, value] of executedSearchMap.entries()) {
        let condition = '';

        if (value.type === 'propfilter') {
          if (value.min && value.max && value.min === value.max) {
            let parsedValue = parseMeasurement(value.min);
            let displayValue = value.min;

            if (isNumeric(value.min)) {
              displayValue = mathjs.format(Number(value.min), 4).replace('e+', 'E').replace('e-', 'E-');
            }
            condition += ' = ' + displayValue + (value.unit && !parsedValue.unit ? ' ' + value.unit : '');
          } else {
            if (value.min) {
              let unit = value.unit;
              let parsedValue = parseMeasurement(value.min);
              if (parsedValue.unit) {
                unit = parsedValue.unit;
              }
              if (value.max) {
                let parsedMaxValue = parseMeasurement(value.max);
                if (!parsedValue.unit && parsedMaxValue.unit) {
                  unit = parsedMaxValue.unit;
                }
              }

              let displayValue = value.min;

              if (isNumeric(value.min)) {
                displayValue = mathjs.format(Number(value.min), 4).replace('e+', 'E').replace('e-', 'E-');
              }
              condition += ' \u2265 ' + displayValue + (unit && !parsedValue.unit ? ' ' + unit : '');
            }

            if (value.max) {
              let unit = value.unit;
              let parsedValue = parseMeasurement(value.max);
              if (parsedValue.unit) {
                unit = parsedValue.unit;
              }

              if (value.min) {
                let parsedMinValue = parseMeasurement(value.min);
                if (!parsedValue.unit && parsedMinValue.unit) {
                  unit = parsedMinValue.unit;
                }
              }

              let displayValue = value.max;

              if (isNumeric(value.max)) {
                displayValue = mathjs.format(Number(value.max), 4).replace('e+', 'E').replace('e-', 'E-');
              }

              if (condition) {
                condition += ' and ';
              }
              condition += ' \u2264 ' + displayValue + (unit && !parsedValue.unit ? ' ' + unit : '');
            }
          }

          if (value.text) {
            condition += ' = ' + value.text;
          }
        }

        let displayName = value.name;

        if (value.condition) {
          displayName += ', ' + value.condition;
        }

        if (Object.entries(value).length > 0) {
          elements.push(
            <FilterButton key={key} onClick={() => handleRemove(key)} tooltip={value.standard_name} text={displayName + condition} />
          );
        }
      }

      setBreadcrumbs(elements);
    } else {
      setBreadcrumbs([]);
    }
  }, [executedSearchMap, handleRemove, parseMeasurement]);

  useEffect(() => {
    updateBreadcrumbs(breadcrumbs);
  }, [breadcrumbs, updateBreadcrumbs]);

  function getKey(child) {
    return child.type + '-' + child.vkey;
  }

  const getComponentKey = useCallback((child, type) => {
    return child.type + '-' + child._key + '-' + type;
  }, []);

  function onDropdownChange(event, item) {
    if (item) {
      setSelectedKeys(item.selected ? [...selectedKeys, item.key] : selectedKeys.filter((key) => key !== item.key));
    }
  }

  function handleSave() {
    dismissSaveDialog();

    let criteria = { name: currentName, id: currentId };
    handleSaveSearchCriteria(criteria);
  }

  const handleDeleteSearchCriteria = useCallback(
    async (_criterias) => {
      const ids = _criterias.map((c) => c.id).filter((id) => !id.startsWith('Application'));
      await api.materials.removeSearchCriteria(ids);

      getSearchCriteria();
    },
    [api.materials, getSearchCriteria]
  );

  function convertObjecttoQs(qs) {
    const params = new URLSearchParams();
    Object.keys(qs).forEach((key) => {
      params.append(key, qs[key]);
    });

    const query = params.toString();
    return query ? `?${query}` : '';
  }

  const serializeSearchMap = useCallback(() => {
    let searchArray = [];

    for (const [key, value] of searchMap.entries()) {
      let currentObject = { key: key, type: value.type, name: value.name, baseunit: value.baseunit };
      if (value.type === 'propfilter') {
        if (value.min) {
          currentObject.min = convertUnit(value.min, value.unit, value.baseunit, 0);
        }

        if (value.max) {
          currentObject.max = convertUnit(value.max, value.unit, value.baseunit, 0);
        }

        if (value.text) {
          currentObject.text = value.text;
        }
      } else if (value.type === 'Mpt' || value.type === 'Feature') {
        currentObject.value = value.value;
      }

      searchArray.push(currentObject);
    }

    return searchArray;
  }, [searchMap]);

  useEffect(() => {
    if (!hasMaterials && hadMaterials) {
      if (filterDataStatus === 'RELOAD') {
        savedSearchArray.current = serializeSearchMap();
      }
      setCurrentName('');
      setSearchMap(new Map());
      setExecutedSearchMap(null);
    } else if (hasMaterials && !hadMaterials) {
      setHadMaterials(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMaterials, filterDataStatus, hadMaterials]);

  useEffect(() => {
    if (unitMap && unitMap.size > 0) {
      if (!isEmpty(savedSearchArray.current)) {
        let newSearchMap = deserializeSearchArray(savedSearchArray.current);

        setSearchMap(new Map(newSearchMap));
        setExecutedSearchMap(new Map(newSearchMap));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitMap]);

  const handleSaveSearchCriteria = useCallback(
    async (criteria) => {
      const searchCriteriaInput = {
        id: criteria.id,
        name: criteria.name,
        format: FORMAT,
        criterias: serializeSearchMap(),
        qs: qs.current,
      };

      const { data } = await api.materials.saveSearchCriteria(searchCriteriaInput, appContent);
      if (data.criteria && data.criteria.id) {
        setCurrentId(data.criteria.id);
      }

      getSearchCriteria();

      //const { data } = await createSearchCriteria({ variables: { searchCriteriaInput } });
      //return get(data, 'criteria');

      setPendingChanges(false);
    },
    [api.materials, qs, appContent, getSearchCriteria, serializeSearchMap]
  );

  function handleSaveAs(name) {
    dismissSaveAsDialog();
    setCurrentName(name);

    let criteria = { name: name, id: null };

    for (let existingCriteria of criterias) {
      if (existingCriteria.name === name) {
        criteria = existingCriteria;
        setCurrentId(existingCriteria.id);
        break;
      }
    }
    handleSaveSearchCriteria(criteria);
  }

  function dismissSaveAsDialog() {
    setIsSaveAsDialogHidden(true);
  }

  function dismissLoadDialog() {
    setIsLoadDialogHidden(true);
  }

  function dismissSaveDialog() {
    setIsSaveDialogHidden(true);
  }

  const onValueChange = useCallback(
    (child, type, value) => {
      let key = getKey(child);
      let currentValue = searchMap.get(key);

      let newValue = {
        type: 'propfilter',
        name: child.name,
        unit: child.catalog.unit,
        baseunit: child.catalog.baseunit,
        standard_name: child.standard_name,
        condition: child.condition,
      };

      if (value) {
        if (type === 'min' || type === 'minmax') {
          if (value !== '') {
            newValue.min = value;
          } else if (value !== '' && child.catalog.specialvalueset) {
            newValue.min = value;
          } else {
            newValue.min = currentValue.min;
          }

          if (type === 'min') {
            if (currentValue && currentValue.max) {
              newValue.max = currentValue.max;
            }
          }
        }

        if (type === 'max' || type === 'minmax') {
          if (value !== '') {
            newValue.max = value;
          } else if (value !== '' && child.catalog.specialvalueset) {
            newValue.max = value;
          } else {
            newValue.max = currentValue.max;
          }

          if (type === 'max') {
            if (currentValue && currentValue.min) {
              newValue.min = currentValue.min;
            }
          }
        } else if (type === 'text') {
          if (value !== '') {
            newValue.text = value;
          }
        }
      }

      if (newValue.min || newValue.max || newValue.text) {
        setSearchMap(new Map(searchMap.set(key, newValue)));
      } else {
        let newMap = new Map(searchMap);
        newMap.delete(key);
        setSearchMap(newMap);
      }

      setPendingChanges(true);
    },
    [searchMap]
  );

  const createSearchMap = function (arr, idList, res = new Map()) {
    arr.forEach((i) => {
      if (i.type === 'Group') {
        createSearchMap(i.children, idList, res);
      } else if (idList) {
        let value = idList.get(i.vkey);
        if (value && i.type === 'Spt') {
          let newValue = {
            type: 'propfilter',
            name: i.name,
            unit: i.catalog.unit,
            baseunit: i.catalog.baseunit,
            standard_name: i.standard_name,
            condition: i.condition,
            min: String(value.min),
            max: String(value.max),
          };
          res.set(`${i.type}-${i.vkey}`, newValue);
        }
        if (i.children.length) {
          createSearchMap(i.children, idList, res);
        }
      }
    });
    return res;
  };

  const getPropValue = useCallback(
    (child, type, map = searchMap) => {
      if (map) {
        const result = map.get(getKey(child));

        if (result) {
          if (type === 'min' || type === 'minmax') {
            return result.min ? result.min : '';
          } else if (type === 'max') {
            return result.max ? result.max : '';
          } else if (type === 'text') {
            return result.text ? result.text : '';
          }
        }
      }

      return '';
    },
    [searchMap]
  );

  const validateField = useCallback(
    (child) => {
      let currentObject = searchMap.get(getKey(child));
      let errorMessage = null;
      let hasErrors = false;

      let newUnit = null;

      if (currentObject) {
        if (child.vtype === 'numeric') {
          let originalRangeMin = ranges.Spt[child.vkey][0];
          let originalRangeMax = ranges.Spt[child.vkey][1];

          let rangeMin = originalRangeMin;
          let rangeMax = originalRangeMax;

          if (currentObject && currentObject.min && !child.catalog.specialvalueset) {
            let min = currentObject.min;
            let parsedValue = parseMeasurement(min);

            if (parsedValue.unit) {
              newUnit = parsedValue.unit;
            }

            if (currentObject.max) {
              if (!parsedValue.unit) {
                let parsedMaxValue = parseMeasurement(currentObject.max);
                if (parsedMaxValue.unit) {
                  newUnit = parsedMaxValue.unit;
                  min = currentObject.min + ' ' + parsedMaxValue.unit;
                }
              }
            }
            let convertedInput = convertInput(min, child.unit_name);

            if (convertedInput === '') {
              errorMessage = TextMapping.getUIText(TextMapping.UI_TEXT_CANNOT_CONVERT, texts, {
                value: min,
                target: child.unit_name,
              });
            } else {
              if (newUnit) {
                rangeMin = convertUnit(originalRangeMin, child.unit_name, newUnit);
                rangeMax = convertUnit(originalRangeMax, child.unit_name, newUnit);
              }

              if (
                ranges.Spt[child.vkey] &&
                ranges.Spt[child.vkey].length === 2 &&
                (!isFinite(Number(convertedInput)) || Number(convertedInput) > ranges.Spt[child.vkey][1])
              ) {
                errorMessage = TextMapping.getUIText(TextMapping.UI_TEXT_VALUE_RANGE, texts, {
                  min: rangeMin,
                  max: rangeMax,
                });
              }
            }
          }

          if (currentObject && currentObject.max && !child.catalog.specialvalueset) {
            let max = currentObject.max;
            let parsedValue = parseMeasurement(max);

            if (parsedValue.unit) {
              newUnit = parsedValue.unit;
            }

            if (currentObject.min) {
              if (!parsedValue.unit) {
                let parsedMinValue = parseMeasurement(currentObject.min);

                if (parsedMinValue.unit) {
                  max = currentObject.max + ' ' + parsedMinValue.unit;
                  newUnit = parsedMinValue.unit;
                }
              }
            }

            let convertedInput = convertInput(max, child.unit_name);

            if (convertedInput === '') {
              errorMessage = TextMapping.getUIText(TextMapping.UI_TEXT_CANNOT_CONVERT, texts, {
                value: max,
                target: child.unit_name,
              });
            } else {
              if (newUnit) {
                rangeMin = convertUnit(originalRangeMin, child.unit_name, newUnit);
                rangeMax = convertUnit(originalRangeMax, child.unit_name, newUnit);
              }

              if (
                ranges.Spt[child.vkey] &&
                ranges.Spt[child.vkey].length === 2 &&
                (!isFinite(Number(convertedInput)) || Number(convertedInput) < ranges.Spt[child.vkey][0])
              ) {
                errorMessage = TextMapping.getUIText(TextMapping.UI_TEXT_VALUE_RANGE, texts, {
                  min: rangeMin,
                  max: rangeMax,
                });
              }
            }
          }
        } else if (child.vtype === 'text') {
          if (!currentObject.text || currentObject.text === '') {
            errorMessage = 'Please supply a value';
          }
        }

        if (errorMessage) {
          hasErrors = true;
        }

        currentObject.errorMessage = errorMessage;
      }

      // Check for other errors
      for (const value of searchMap.values()) {
        if (value.errorMessage && value.errorMessage !== null) {
          hasErrors = true;
          break;
        }
      }

      if (currentObject && !isEmpty(currentObject.errorMessage)) {
        let newSearchMap = new Map(searchMap.set(getKey(child), currentObject));
        setSearchMap(newSearchMap);
      }

      return !hasErrors;
    },
    [ranges, searchMap, texts, convertInput, parseMeasurement]
  );

  const onCheckboxChange = useCallback(
    (child, value, type, parentVkey, parentUseMask) => {
      let key = getKey(child);

      let newValue = { type: type, value: value, name: child.name, parentVkey: parentVkey, parentUseMask: parentUseMask };

      let newMap = new Map(searchMap);
      if (value) {
        newMap.set(key, newValue);
      } else {
        newMap.delete(key);
      }

      if (validateField(child)) {
        setSearchMap(new Map(newMap));
        setExecutedSearchMap(new Map(newMap));
      }
    },
    [searchMap, validateField]
  );

  const triggerSearch = useCallback(
    (child, type) => {
      if (getPropValue(child, type) !== getPropValue(child, type, executedSearchMap)) {
        if (validateField(child)) {
          setExecutedSearchMap(new Map(searchMap));
        }
      }
    },
    [executedSearchMap, getPropValue, validateField, searchMap]
  );

  const handleKeyPress = useCallback(
    (event, child, type) => {
      if (event.key === 'Enter') {
        triggerSearch(child, type);
      }
    },
    [triggerSearch]
  );

  function onFilterChange(event, value) {
    if (!isEmpty(value) && !isEmpty(collapsedVkeys)) {
      setPreviousCollapsedVkeys([...collapsedVkeys]);
      setCollapsedVkeys([]);
    } else if (isEmpty(value)) {
      setCollapsedVkeys([...previousCollapsedVkeys]);
    }

    setFilter(value);
  }

  function getDisplayName(child) {
    let content = child.name;
    if (child.node && child.node.config && child.node.config.content) {
      content = child.node.config.content.replace(/{%(?![0-9A-Fa-f]{2})[^%]+%}/g, function (all) {
        return child ? child[all.split('{%').join('').split('%}').join('')] || '' : '';
      });
    }

    return content;
  }

  const getErrorMessage = useCallback(
    (child, map = searchMap) => {
      if (map) {
        const result = map.get(getKey(child));

        if (result) {
          return result.errorMessage;
        }
      }

      return null;
    },
    [searchMap]
  );

  const getCheckboxValue = useCallback(
    (child) => {
      const result = searchMap.get(getKey(child));
      if (result && result.value) {
        return true;
      } else {
        return false;
      }
    },
    [searchMap]
  );

  const handleToggler = useCallback((vkey, value) => {
    if (value === true) {
      setCollapsedVkeys((oldCollapsedVkeys) => [...oldCollapsedVkeys, vkey]);
    } else {
      setCollapsedVkeys((oldCollapsedVkeys) =>
        oldCollapsedVkeys.filter((collapsedVkey) => {
          return collapsedVkey !== vkey;
        })
      );
    }
  }, []);

  const mapChildren = useCallback(
    (parentVkey, child, fields, depth, parentMatchedFilter, categorySelected, tableFields, newUnitMap, parentUseMask) => {
      let hasResults = false;
      let margin = depth * 10;
      let matchedFilter = false;

      if (child.type !== 'Group') {
        let input = [];
        let range = '';
        switch (child.vtype) {
          case 'numeric':
            newUnitMap.set(child.type + '-' + child.vkey, child.catalog.unit);

            if (child.catalog.specialvalueset) {
              if (
                child.node &&
                child.node.config &&
                (child.node.config.range === 'minmax' || child.node.config.range === 'min' || child.node.config.range === 'max')
              ) {
                input.push(
                  <AdvancedSearchSpecialValue
                    key={getComponentKey(child, child.node.config.range + 'special')}
                    child={child}
                    type={child.node.config.range}
                    getComponentKey={getComponentKey}
                    triggerSearch={triggerSearch}
                    getPropValue={getPropValue}
                    onValueChange={onValueChange}
                  />
                );
              } else {
                input.push(
                  <AdvancedSearchSpecialValue
                    key={getComponentKey(child, 'minspecial')}
                    child={child}
                    type={'min'}
                    getComponentKey={getComponentKey}
                    triggerSearch={triggerSearch}
                    getPropValue={getPropValue}
                    onValueChange={onValueChange}
                  />
                );

                input.push(
                  <AdvancedSearchSpecialValue
                    key={getComponentKey(child, 'maxspecial')}
                    child={child}
                    type={'max'}
                    getComponentKey={getComponentKey}
                    triggerSearch={triggerSearch}
                    getPropValue={getPropValue}
                    onValueChange={onValueChange}
                  />
                );
              }
            } else {
              if (
                child.node &&
                child.node.config &&
                (child.node.config.range === 'minmax' || child.node.config.range === 'min' || child.node.config.range === 'max')
              ) {
                input.push(
                  <AdvancedSearchTextField
                    key={getComponentKey(child, child.node.config.range + 'field')}
                    child={child}
                    type={child.node.config.range}
                    getPropValue={getPropValue}
                    getComponentKey={getComponentKey}
                    placeholder={child.node.config.range}
                    triggerSearch={triggerSearch}
                    handleKeyPress={handleKeyPress}
                    onValueChange={onValueChange}
                  />
                );
              } else {
                input.push(
                  <AdvancedSearchTextField
                    key={getComponentKey(child, 'minfield')}
                    child={child}
                    type={'min'}
                    getPropValue={getPropValue}
                    getComponentKey={getComponentKey}
                    placeholder="min"
                    triggerSearch={triggerSearch}
                    handleKeyPress={handleKeyPress}
                    onValueChange={onValueChange}
                  />
                );
                input.push(
                  <AdvancedSearchTextField
                    key={getComponentKey(child, 'maxfield')}
                    child={child}
                    type={'max'}
                    getPropValue={getPropValue}
                    getComponentKey={getComponentKey}
                    placeholder="max"
                    triggerSearch={triggerSearch}
                    handleKeyPress={handleKeyPress}
                    onValueChange={onValueChange}
                  />
                );
              }
            }
            break;
          case 'text':
            input.push(
              <AdvancedSearchTextField
                key={getComponentKey(child, 'textfield')}
                child={child}
                type={'text'}
                getPropValue={getPropValue}
                getComponentKey={getComponentKey}
                placeholder="value"
                triggerSearch={triggerSearch}
                handleKeyPress={handleKeyPress}
                onValueChange={onValueChange}
              />
            );
            break;
          default:
        }

        let newUnit = null;

        if (child.vtype === 'numeric' || child.vtype === 'text') {
          if (
            ranges &&
            ranges.Spt &&
            ranges.Spt[child.vkey] &&
            ranges.Spt[child.vkey].length === 2 &&
            (ranges.Spt[child.vkey][0] || ranges.Spt[child.vkey][0] === 0) &&
            (ranges.Spt[child.vkey][1] || ranges.Spt[child.vkey][1] === 0)
          ) {
            let searchMapResult = searchMap.get(getKey(child));
            if (searchMapResult) {
              if (searchMapResult.min) {
                let measurement = parseMeasurement(searchMapResult.min);
                if (measurement.unit) {
                  newUnit = measurement.unit;
                }
              }

              if (searchMapResult.max) {
                let measurement = parseMeasurement(searchMapResult.max);
                if (measurement.unit) {
                  newUnit = measurement.unit;
                }
              }
            }

            let rangeMin = ranges.Spt[child.vkey][0];
            let rangeMax = ranges.Spt[child.vkey][1];

            if (newUnit) {
              rangeMin = convertUnit(rangeMin, child.unit_name, newUnit);
              rangeMax = convertUnit(rangeMax, child.unit_name, newUnit);
            }

            range = `${typeof rangeMin === 'number' ? mathjs.format(rangeMin, 3).replace('e+', 'E').replace('e-', 'E-') : rangeMin} - ${
              typeof rangeMax === 'number' ? mathjs.format(rangeMax, 3).replace('e+', 'E').replace('e-', 'E-') : rangeMax
            }`;
          }
        }

        if (child.type === 'Mpt' || child.type === 'Feature') {
          input.push(
            <AdvancedSearchCheckbox
              parentVkey={parentVkey}
              key={getComponentKey(child, 'checkboxfield')}
              child={child}
              getComponentKey={getComponentKey}
              getCheckboxValue={getCheckboxValue}
              onCheckboxChange={onCheckboxChange}
              parentUseMask={parentUseMask}
            />
          );
        }

        if (
          (executedSearchMap && executedSearchMap.has(getKey(child))) ||
          (child.vtype === 'text' && range !== '') ||
          (child.vtype === 'numeric' && range !== '') ||
          (child.type === 'Mpt' && ranges && ranges.Diagram && ranges.Diagram.includes(child.vkey)) ||
          (child.type === 'Feature' && ranges && ranges.Feature && ranges.Feature.includes(child.vkey))
        ) {
          if (filter === '' || child.name.toLowerCase().includes(filter.toLowerCase().trim())) {
            matchedFilter = true;
          }
        }

        if (child.type === 'Mpt' || child.type === 'Feature') {
          let hadRange =
            (child.type === 'Mpt' && ranges && ranges.Diagram && ranges.Diagram.includes(child.vkey)) ||
            (child.type === 'Feature' && ranges && ranges.Feature && ranges.Feature.includes(child.vkey));

          fields.push(
            <div
              key={getComponentKey(child, 'checkboxrow')}
              style={{
                display: (parentMatchedFilter && hadRange) || matchedFilter ? 'flex' : 'none',
                marginLeft: '23px',
                gap: '5px',
                paddingTop: '5px',
                paddingBottom: '5px',
                alignItems: 'center',
                width: '45%',
                paddingLeft: margin + 'px',
              }}
            >
              {input}
            </div>
          );

          if (parentMatchedFilter || matchedFilter) {
            numberOfFieldsDisplayed.current++;
          }
        } else {
          let errorMessage = getErrorMessage(child);

          fields.push(
            <div
              key={getComponentKey(child, 'error')}
              style={{
                display: errorMessage ? 'block' : 'none',
                color: theme?.semanticColors?.errorMessageBorderColor || '#FF0000',
                marginLeft: '33px',
              }}
            >
              {errorMessage}
            </div>
          );

          fields.push(
            <AdvancedSearchRow
              key={getComponentKey(child, 'inputrow')}
              child={child}
              newUnit={newUnit}
              errorMessage={errorMessage}
              getComponentKey={getComponentKey}
              parentMatchedFilter={parentMatchedFilter}
              matchedFilter={matchedFilter}
              margin={margin}
              input={input}
              range={range}
              getDisplayName={getDisplayName}
              theme={theme}
            />
          );

          if (parentMatchedFilter || matchedFilter) {
            tableFields.count++;
            numberOfFieldsDisplayed.current++;
          }
        }

        const field = {
          type: child.vtype,
          title: `${child.name} ${child.standard_name ? '(' + child.standard_name + ')' : ''}`,
          vkey: child.vkey,
          unit: child.unit_name,
          parentVkey: parentVkey,
        };

        if (child.unit_name) {
          field.siUnit = child.unit_name;
        }

        if (field.type) {
          fields[child.type + '/' + child.vkey.toString()] = field;
        }
      } else if (child.children && child.children.length > 0) {
        if ((filter !== '' && child.name.toLowerCase().includes(filter.toLowerCase().trim())) || parentMatchedFilter) {
          matchedFilter = true;
        }

        let newFields = [];

        if (depth === 0 && selectedKeys.includes(child.vkey)) {
          categorySelected = true;
        }

        let tableFields = { count: 0 };

        if (firstLoad && appContent && appContent.config.advsearchdialog && appContent.config.advsearchdialog.collapsed) {
          handleToggler(child._key, true);
        }

        if (!collapsedVkeys.includes(child._key) || filter !== '') {
          for (let newChild of child.children) {
            let result = mapChildren(
              child.vkey,
              newChild,
              newFields,
              depth + 1,
              filter !== '' && matchedFilter,
              categorySelected,
              tableFields,
              newUnitMap,
              child.use_mask
            );
            if (result) {
              hasResults = true;
            }
          }
        } else if (collapsedVkeys.includes(child._key)) {
          let tempNewFields = [];
          let tempTableFields = { count: 0 };
          for (let newChild of child.children) {
            let result = mapChildren(
              child.vkey,
              newChild,
              tempNewFields,
              depth + 1,
              filter !== '' && matchedFilter,
              categorySelected,
              tempTableFields,
              newUnitMap
            );
            if (result) {
              hasResults = true;
            }
          }
        }

        if (depth === 0 && hasResults) {
          groups.current.push({ key: child.vkey, text: child.name });
        }

        if (selectedKeys.length === 0 || categorySelected) {
          fields.push(
            <AdvancedSearchGroup
              key={getComponentKey(child, 'grouprow')}
              child={child}
              filter={filter}
              margin={margin}
              matchedFilter={matchedFilter}
              hasResults={hasResults}
              collapsedVkeys={collapsedVkeys}
              getComponentKey={getComponentKey}
              handleToggler={handleToggler}
            />
          );

          if ((hasResults || matchedFilter) && !collapsedVkeys.includes(child._key)) {
            fields.push(
              <div key={getComponentKey(child, 'headerrow')} style={{ width: '100%', display: tableFields.count > 0 ? 'block' : 'none' }}>
                <div
                  className="bodyText"
                  style={{
                    display: tableFields.count > 0 ? 'flex' : 'none',
                    marginLeft: '23px',
                    gap: '5px',
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    alignItems: 'center',
                    width: '100%',
                    borderBottom: '1px solid #DDDDDD',
                    fontWeight: 'bold',
                  }}
                >
                  <div style={{ width: '40%', paddingLeft: 10 + margin + 'px' }}>
                    {TextMapping.getUIText(TextMapping.UI_TEXT_NAME, texts)}
                  </div>
                  <div style={{ width: '25%' }}>{TextMapping.getUIText(TextMapping.UI_TEXT_SEARCH_INPUT, texts)}</div>
                  <div style={{ width: '15%' }}>{TextMapping.getUIText(TextMapping.UI_TEXT_UNIT, texts)}</div>
                  <div style={{ width: '20%' }}>{TextMapping.getUIText(TextMapping.UI_TEXT_RANGE, texts)}</div>
                </div>
              </div>
            );

            fields.push(newFields);
          }
        }
      }

      setGroupOptions(groups.current);

      return hasResults || matchedFilter || parentMatchedFilter;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      collapsedVkeys,
      getComponentKey,
      executedSearchMap,
      filter,
      getCheckboxValue,
      getErrorMessage,
      getPropValue,
      groups,
      handleKeyPress,
      onCheckboxChange,
      onValueChange,
      ranges,
      selectedKeys,
      texts,
      triggerSearch,
      handleToggler,
      appContent,
    ]
  );

  useEffect(() => {
    fields.current = [];
    groups.current = [];

    let newUnitMap = new Map();
    if (appContent && searchCatalog && searchCatalog.length > 0) {
      numberOfFieldsDisplayed.current = 0;
      for (let child of searchCatalog[0].children) {
        mapChildren(searchCatalog[0].name, child, fields.current, 0, false, false, { count: 0 }, newUnitMap);
      }

      setFirstLoad(false);

      setFormFields(fields.current);

      setUnitMap(newUnitMap);
      if (!hasUnitMap) {
        setHasUnitMap(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCatalog, ranges, selectedKeys, filter, searchMap, collapsedVkeys, fields, mapChildren, appContent]);

  let opacity = rangeStatus === 'Loading' ? '0.5' : '1.0';

  if (permission && !perms.includes(permission)) {
    return (
      <div style={{ height: 'calc(100vh - 135px)', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
        <BBPageComponent
          page={restrictedPage}
          bbPageForComponent={bbPageForComponent}
          getBBPageForComponent={getBBPageForComponent}
          appContent={appContent}
          language={language}
          texts={texts}
        />
        <ConfirmDialog
          isOpen={isConfirmDialogVisible}
          body="Do you want to replace your existing favorites?"
          onConfirm={() => {
            getSearchCriteria(true);
            setConfirmDialogVisible(false);
          }}
          onReject={() => {
            setConfirmDialogVisible(false);
            getSearchCriteria();
          }}
        />
      </div>
    );
  }

  return (
    <div style={{ padding: '10px', height: 'calc(100vh - 135px)', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
      {rangeStatus !== 'SUCCESS' && (
        <div
          style={{
            zIndex: '10',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(255,255,255,.5)',
            position: 'absolute',
            width: '100%',
            height: '100%',
          }}
        >
          <Spinner size={SpinnerSize.large} />
        </div>
      )}
      <div
        className="Action"
        style={{
          display: appContent && appContent.user && appContent.user.companyId > 0 ? 'flex' : 'none',
          columnGap: '5px',
          padding: '5px',
          justifyContent: 'right',
        }}
      >
        <DefaultButton
          data-testid="search-form-save"
          //disabled={state.isSaveDisabled}
          iconProps={{ iconName: 'Save' }}
          onClick={() => setIsSaveDialogHidden(false)}
          disabled={!(currentId !== null && currentName !== '' && pendingChanges)}
          text={TextMapping.getUIText(TextMapping.UI_TEXT_SAVE, texts)}
        />
        <DefaultButton
          data-testid="search-form-save-as"
          //disabled={state.isSaveAsDisabled}
          iconProps={{ iconName: 'SaveAs' }}
          onClick={() => setIsSaveAsDialogHidden(false)}
          text={TextMapping.getUIText(TextMapping.UI_TEXT_SAVE_AS, texts)}
        />
        <DefaultButton
          data-testid="search-form-load-criteria"
          //disabled={criterias.length === 0}
          iconProps={{ iconName: 'UploadAlt' }}
          onClick={() => setIsLoadDialogHidden(false)}
          text={TextMapping.getUIText(TextMapping.UI_TEXT_LOAD_CRITERIA, texts)}
        />
        <DefaultButton
          data-testid="search-form-clear-search"
          //disabled={criterias.length === 0}
          iconProps={{ iconName: 'Clear' }}
          onClick={resetFilters}
          text={TextMapping.getUIText(TextMapping.UI_TEXT_CLEAR_SEARCH, texts)}
        />
      </div>
      <div className="amdc-advanced-search" style={{ display: 'flex', paddingBottom: '10px', paddingTop: '10px', flexWrap: 'wrap' }}>
        {breadcrumbs}
      </div>
      <div style={{ paddingBottom: '10px' }}>
        <Dropdown
          placeholder={TextMapping.getUIText(TextMapping.UI_TEXT_SELECT_CATEGORY, texts)}
          selectedKeys={selectedKeys}
          onChange={onDropdownChange}
          options={groupOptions}
          styles={dropdownStyles}
          multiSelect
        />
        <Margin5 />
        <SearchBox
          onChange={onFilterChange}
          value={filter}
          placeholder={TextMapping.getUIText(TextMapping.UI_TEXT_FILTER_BY_PROPERTY_NAME, texts)}
        />
      </div>
      <div
        data-testid="advanced-search-scrollable-div"
        style={{
          display: 'flex',
          width: '100%',
          flexWrap: 'wrap',
          opacity: opacity,
          alignItems: 'flex-start',
          alignContent: 'flex-start',
          overflow: 'auto',
        }}
      >
        {filter !== '' && numberOfFieldsDisplayed.current === 0
          ? TextMapping.getUIText(TextMapping.UI_TEXT_NO_FIELDS_MATCH_FILTER, texts)
          : ''}

        {formFields}
      </div>
      <LoadCriteriaDialog
        criterias={criterias}
        hidden={isLoadDialogHidden}
        onDelete={handleDeleteSearchCriteria}
        onDismiss={() => setIsLoadDialogHidden(true)}
        onLoad={handleLoadCriteria}
        texts={texts}
      />
      <SaveCriteriaAsDialog
        criteriaNames={criteriaNames}
        hidden={isSaveAsDialogHidden}
        onDismiss={() => setIsSaveAsDialogHidden(true)}
        onSave={(name) => handleSaveAs(name)}
        texts={texts}
      />
      <SaveCriteriaDialog hidden={isSaveDialogHidden} onDismiss={() => setIsSaveDialogHidden(true)} onSave={handleSave} texts={texts} />
      <ConfirmDialog
        isOpen={isConfirmDialogVisible}
        body="Do you want to replace your existing favorites?"
        onConfirm={() => {
          getSearchCriteria(true);
          setConfirmDialogVisible(false);
        }}
        onReject={() => {
          setConfirmDialogVisible(false);
          getSearchCriteria();
        }}
      />
    </div>
  );
}

const AdvancedSearch = styled(AdvancedSearchBase, () => {}, undefined, { scope: 'AdvancedSearchBase' });

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(AdvancedSearch));
