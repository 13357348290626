/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { ContextualMenu, SelectAllVisibility, ShimmeredDetailsList, Selection, ConstrainMode } from '@fluentui/react';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import { CheckboxVisibility } from './data/CheckboxVisibility';
import { createColumns } from './utils/createColumns';
import { createContextMenuProps } from './utils/createContextMenuProps';
import { sortEntities as internalSortEntities } from './utils/sortEntities';
import { isEqual } from 'lodash';
import './styles.css';

const EMPTY_ARRAY = [];
const NOOP = () => {};

const defaultRenderRow = (props, defaultRender) => {
  return defaultRender({ ...props });
};

const defaultRenderDetailsHeader = (props, defaultRender) => {
  return defaultRender({ ...props, selectAllVisibility: SelectAllVisibility.hidden });
};

export function EntityList({
  actions = null,
  checkboxVisibility = 'hidden',
  selectAllVisibility = 1,
  columns,
  enableShimmer = false,
  entities = EMPTY_ARRAY,
  onActiveEntityChanged = NOOP,
  onContextMenuItemClick = NOOP,
  onEntityInvoke = NOOP,
  onRenderRow = defaultRenderRow,
  onRenderDetailsHeader = defaultRenderDetailsHeader,
  onSelectedEntitiesChange = NOOP,
  onColumnHeaderClick = NOOP,
  selectedEntities = EMPTY_ARRAY,
  shimmerLines = null,
  sortEntities = internalSortEntities,
  sortDirection,
  sortColumn,
}) {
  const [contextMenuProps, setContextMenuProps] = useState();
  const [selectedIndices, setSelectedIndices] = useState([]);
  const [previousSelectedIndices, setPreviousSelectedIndices] = useState([]);

  const previousSelectedEntities = useRef(selectedEntities);
  // eslint-disable-next-line no-unused-vars
  const [forceChange, setForceChange] = useState();

  const internalColumns = useMemo(() => {
    let ascending = sortDirection === 'ASC' ? true : false;
    return createColumns({ columns, onColumnClick: NOOP, sortingEntityPath: sortColumn, sortingIsAscending: ascending });
  }, [columns, sortColumn, sortDirection]);

  const internalEntities = useMemo(() => {
    return sortEntities({ entities });
  }, [entities, sortEntities]);

  const selection = useMemo(
    () =>
      new Selection({
        getKey: (item) => (item ? item.id : null),
        onSelectionChanged: () => {
          setForceChange([]);
          setSelectedIndices(selection.getSelectedIndices());
        },
      }),
    []
  );

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (!enableShimmer) {
      if (!isEqual(selectedIndices, previousSelectedIndices)) {
        const selectedItems = selectedIndices.map((index) => entities[index]);
        // Preserve selections that are not in the new list
        const selectedItemsNotFound = selectedEntities.filter((entity) => !entities.some((e) => e.id === entity.id));

        onSelectedEntitiesChange(selectedItems.concat(selectedItemsNotFound));
      }
      setPreviousSelectedIndices(selectedIndices);
    }
  }, [
    selectedIndices,
    enableShimmer,
    entities,
    selectedEntities,
    onSelectedEntitiesChange,
    previousSelectedIndices,
    setPreviousSelectedIndices,
  ]);

  useEffect(() => {
    restoreSelection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entities]);

  useEffect(() => {
    if (selectionsChanged()) {
      restoreSelection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEntities]);

  function selectionsChanged() {
    if (selectedEntities === previousSelectedEntities.current) return false;
    if (selectedEntities == null || previousSelectedEntities.current == null) return true;
    if (selectedEntities.length !== previousSelectedEntities.current.length) return true;

    for (var i = 0; i < selectedEntities.length; ++i) {
      if (selectedEntities[i].id !== previousSelectedEntities.current[i].id) return true;
    }
    return false;
  }

  // Restores the saved selected indices array.
  const restoreSelection = () => {
    previousSelectedEntities.current = selectedEntities;

    const items = selectedEntities;

    selection.setAllSelected(false);

    for (const item of items) {
      if (item) {
        selection.setKeySelected(item.id, true, false);
      }
    }
  };

  const handleContextMenu = useCallback(
    (clickedEntity, index, event) => {
      if (!actions) {
        return;
      }

      const newContextMenuProps = createContextMenuProps({
        actions,
        clickedEntity,
        event,
        onContextMenuItemClick,
        onDismiss: () => setContextMenuProps(null),
        selectedEntities: selection.getSelection(),
      });

      setContextMenuProps(newContextMenuProps);
    },
    [actions, onContextMenuItemClick, selection]
  );

  return (
    <div>
      <ShimmeredDetailsList
        checkboxVisibility={CheckboxVisibility[checkboxVisibility]}
        selectAllVisibility={selectAllVisibility}
        columns={internalColumns}
        compact
        enableShimmer={enableShimmer}
        constrainMode={ConstrainMode.unconstrained}
        items={internalEntities}
        onActiveItemChanged={onActiveEntityChanged}
        onItemContextMenu={handleContextMenu}
        onItemInvoked={onEntityInvoke}
        onRenderRow={onRenderRow}
        onRenderDetailsHeader={onRenderDetailsHeader}
        onColumnHeaderClick={onColumnHeaderClick}
        selection={selection}
        selectionZoneProps={{ selectionClearedOnSurfaceClick: false }}
        selectionPreservedOnEmptyClick={true}
        setKey={0}
        shimmerLines={shimmerLines}
      />
      {contextMenuProps && <ContextualMenu {...contextMenuProps} />}
    </div>
  );
}

EntityList.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      iconProps: PropTypes.shape({
        iconName: PropTypes.string.isRequired,
      }).isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  checkboxVisibility: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  enableShimmer: PropTypes.bool,
  entities: PropTypes.arrayOf(PropTypes.object),
  onActiveEntityChanged: PropTypes.func,
  onContextMenuItemClick: PropTypes.func,
  onEntityInvoke: PropTypes.func,
  onRenderRow: PropTypes.func,
  onRenderDetailsHeader: PropTypes.func,
  onSelectedEntitiesChange: PropTypes.func,
  selectedEntities: PropTypes.arrayOf(PropTypes.object),
  shimmerLines: PropTypes.number,
  sortEntities: PropTypes.func,
};

export default EntityList;
