import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../actions';
import { Footer } from '../../gooey/components/Footer';
import { processBuildingBlock } from '../processBuildingBlock';
import { isEmpty } from 'lodash';

const mapStateToProps = (state) => {
  return {
    config: state.roots.config,
    bbFooter: state.roots.bbFooter,
    ranges: state.roots.ranges,
    canAccess: state.roots.canAccess,
    language: state.roots.language,
    appContent: state.roots.appContent,
    texts: state.roots.texts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(actions, dispatch);
};

function FooterContainer({ appContent, config, bbFooter, getBBFooter, ranges, canAccess, language, texts }) {
  const [collapsedVkeys, setCollapsedVkeys] = useState([]);
  const [activeTabMap, setActiveTabMap] = useState(new Map());
  const [layoutMap, setLayoutMap] = useState(new Map());
  const [addedStyles, setAddedStyles] = useState([]);
  const [body, setBody] = useState([]);

  const onTabClick = useCallback(
    (id, item) => {
      setActiveTabMap(new Map(activeTabMap.set(id, item)));
    },
    [activeTabMap]
  );

  useEffect(() => {
    if (config.footer && language) {
      getBBFooter(config.footer.page);
    }
  }, [config, getBBFooter, language]);

  const addStyle = useCallback(
    (id, style) => {
      if (!addedStyles.includes(id)) {
        var styleSheet = document.createElement('style');

        let styleText = '';
        if (style && style.length > 0) {
          for (let styleElement of style) {
            styleText += '.footer' + styleElement + ' ';
          }
          styleSheet.innerText = styleText;
          document.head.appendChild(styleSheet);
        }

        setAddedStyles((prevStyles) => [...prevStyles, id]);
      }
    },
    [addedStyles]
  );

  const getActiveTab = useCallback(
    (id) => {
      return activeTabMap.get(id)?.props?.itemKey;
    },
    [activeTabMap]
  );

  function handleToggler(vkey, value) {
    if (value === true) {
      setCollapsedVkeys((oldCollapsedVkeys) => [...oldCollapsedVkeys, vkey]);
    } else {
      setCollapsedVkeys((oldCollapsedVkeys) =>
        oldCollapsedVkeys.filter((collapsedVkey) => {
          return collapsedVkey !== vkey;
        })
      );
    }
  }

  function onSetLayoutMap(map) {
    setLayoutMap(map);
  }

  useEffect(() => {
    let newBody = [];

    if (bbFooter) {
      for (let buildingBlock of bbFooter) {
        processBuildingBlock({
          appContent,
          buildingBlock,
          body: newBody,
          collapsedVkeys,
          handleToggler,
          onTabClick,
          getActiveTab,
          canAccess,
          layoutMap,
          setLayoutMap: onSetLayoutMap,
          addStyle,
          ranges,
          texts,
        });
      }
    }

    setBody(newBody);
  }, [appContent, bbFooter, layoutMap, collapsedVkeys, canAccess, ranges, addStyle, getActiveTab, onTabClick, texts]);

  if (config.footer) {
    return <footer className="amdc-block footer">{body}</footer>;
  } else {
    if (!isEmpty(config)) {
      return <Footer appVersion="v2024.1.2" appName="Altair Material Data Center" />;
    } else {
      return null;
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FooterContainer);
